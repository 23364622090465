import {Fade} from 'react-awesome-reveal';
import Mailer from './mailer.js';

const contact = {
  id:'contact',
  intro:'Contact Us',
  phone:{
    intro: 'Mobile: ',
    copy: '0458 876 290',
    url: 'tel:+61458876290'
  },
  email:{
    intro: 'Email: ',
    copy:'info@structuralassessments.com.au',
    url:'mailto:info@structuralassessments.com.au'
  },
  address:{
    intro: 'Our Address:',
    copy:'2/27 Plane St, Thomastown VIC 3074'
  }
};

const Contact = () =>  (
  <section className='container-fluid min-vh-100 p-0 bg-image-map' id={contact.id}>
    <div className='container-fluid p-0 min-vh-100 d-flex flex-column bg-trans-black'>
      <div className='container-fluid flex-fill d-flex justify-content-center align-items-center pt-gen pb-5'>
        <div className='container justify-content-center align-items-center'>
          <Fade direction='left'>
          <div className='col text-end'>
            <h2>{contact.intro}</h2>
          </div>
          </Fade>
        </div>
      </div>
      <div className='container-fluid flex-fill'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 pt-5 text-white'>
              <Mailer />
            </div>
            <div className='col-lg-6 py-5 text-white'>
              {contact.phone.intro}<br/><a href={contact.phone.url} className='text-white'>{contact.phone.copy}</a>
              <br/><br/>
              {contact.email.intro}<br/><a href={contact.email.url} className='text-white'>{contact.email.copy}</a>
              <br/><br/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Contact;
