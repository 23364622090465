import { useState } from 'react';
import Slider from './slider.js';

const Home = () =>  {

  const [color,setColor] =useState(false)
  const changeColor =()=>{
    if (window.scrollY >=80){
      setColor(true)
    } else {
      setColor(false)
    }
  }

  window.addEventListener('scroll', changeColor)

  return(
    <>
      <section className='container-fluid p-0 min-vh-100 bg-image-home'>
        <section className='container-fluid p-0 min-vh-100 d-flex flex-fill justify-content-center align-items-center bg-trans-black'>
          <section className='container'>
            <Slider />
          </section>

          <section className={ color ? 'd-none' : 'fixed-bottom text-center mb-2'}>
            <svg width='50px' height='50px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M9 13L12 16M12 16L15 13M12 16V8M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z' stroke='#ffffff' strokeWidth='1' strokeLinecap='round' strokeLinejoin='round'/></svg>
          </section>
        </section>
      </section>
    </>
  )
}

export default Home;
