

const slider = {
  item01:{
    image:{
      alt:  'Structural Assessments',
      url:  'logo02.png',
      width: 570,
      class: 'mw-90 pb-4'
    },
    copy:{
      title:    'Structural Assessments',
      copy:     <>Your local consulting structural engineers</>
    }
  },
  item02:{
    image:{
      alt: 'Structural Assessments',
      url: 'logo02.png',
      width: 570,
      class: 'mw-90 pb-4'
    },
    copy:{
      title:    'Damaged home?',
      copy:     <>Have you noticed changes to your home's structure? Cracking and leaning? Do windows and doors jamb? <b>Get in touch</b> so we can provide our structural expertise.</>
    }
  },
  item03:{
    image:{
      alt: 'Structural Assessments',
      url: 'logo02.png',
      width: 570,
      class: 'mw-90 pb-4'
    },
    copy:{
      title:    'New home structural concerns?',
      copy:     <>We can provide VCAT compliant expert reports on new homes that may have defects and structural movement consisting of comprehensive soil and footing check, and assessment of the structural condition of your home.</>
    }
  },
  item04:{
    image:{
      alt: 'Structural Assessments',
      url: 'logo02.png',
      width: 570,
      class: 'mw-90 pb-4'
    },
    copy:{
      title:    'Invasive trees?',
      copy:     <>Nearby trees can be highly damaging to the footing of a structure. If you would like to remove a potentially dangerous tree, <b>Get in touch</b>.</>
    }
  },
  item05:{
    image:{
      alt: 'Structural Assessments',
      url: 'logo02.png',
      width: 570,
      class: 'mw-90 pb-4'
    },
    copy:{
      title:    'Asset inspections',
      copy:     <>We can offer asset inspections for local council, business, parks, public places and more.</>
    }
  }
};

const Slider =()=>  (

  <div id='carousel' className='carousel slide' data-bs-interval='5000' data-bs-touch='true' data-bs-ride='carousel' >

    <div className='container carousel-inner'>

      <div className='carousel-item active' data-bs-interval='5000'>
        <div className='row px-4 g-0 justify-content-center align-items-center '>
          <div className='col-lg-6 text-center'>
            <img src={slider.item01.image.url} alt={slider.item01.image.alt} width={slider.item01.image.width} className={slider.item01.image.class} />
          </div>
          <div className='col-lg-6 text-white'>
            <h1>{slider.item01.copy.title}</h1>
            {slider.item01.copy.copy}
          </div>
        </div>
      </div>
      <div className='carousel-item' data-bs-interval='5000'>
        <div className='row px-4 g-0 justify-content-center align-items-center '>
          <div className='col-lg-6 text-center'>
            <img src={slider.item02.image.url} alt={slider.item02.image.alt} width={slider.item02.image.width} className={slider.item02.image.class} />
          </div>
          <div className='col-lg-6 text-white'>
            <h1>{slider.item02.copy.title}</h1>
            {slider.item02.copy.copy}
          </div>
        </div>
      </div>
      <div className='carousel-item' data-bs-interval='5000'>
        <div className='row px-4 g-0 justify-content-center align-items-center '>
          <div className='col-lg-6 text-center'>
            <img src={slider.item03.image.url} alt={slider.item03.image.alt} width={slider.item03.image.width} className={slider.item03.image.class} />
          </div>
          <div className='col-lg-6 text-white'>
            <h1>{slider.item03.copy.title}</h1>
            {slider.item03.copy.copy}
          </div>
        </div>
      </div>
      <div className='carousel-item' data-bs-interval='5000'>
        <div className='row px-4 g-0 justify-content-center align-items-center '>
          <div className='col-lg-6 text-center'>
            <img src={slider.item04.image.url} alt={slider.item04.image.alt} width={slider.item04.image.width} className={slider.item04.image.class} />
          </div>
          <div className='col-lg-6 text-white'>
            <h1>{slider.item04.copy.title}</h1>
            {slider.item04.copy.copy}
          </div>
        </div>
      </div>
      <div className='carousel-item' data-bs-interval='5000'>
        <div className='row px-4 g-0 justify-content-center align-items-center '>
          <div className='col-lg-6 text-center'>
            <img src={slider.item05.image.url} alt={slider.item05.image.alt} width={slider.item05.image.width} className={slider.item05.image.class} />
          </div>
          <div className='col-lg-6 text-white'>
            <h1>{slider.item05.copy.title}</h1>
            {slider.item05.copy.copy}
          </div>
        </div>
      </div>

    </div>
  </div>

)

export default Slider;
