import {Fade} from 'react-awesome-reveal';

const about = {
  id:'aboutus',
  copy:{
    intro: <>Welcome to Structural Assessments, where engineering excellence meets experience</>,
    title: 'About Us',
    copy: <>Structural Assessments is a close-knit team providing forensic and engineering consulting services across Victoria and Australia. Our ever-evolving competencies include small freestanding structures to complex multi lot buildings.<br/><br/>With more than 30 years of extensive building related expertise, the team at Structural Assessments can assist in any building related issues or concerns you may have with your asset. We engage with private clients, builders, architects, underwriters, or any other stakeholders.<br/><br/>Our knowledge of buildings is also supported by over 15 years of Geotechnical services across Melbourne. This means that Structural Assessments understands the ground on which your asset is founded.<br/><br/> Investigative services include comprehensive visual and tactile inspection, compiling data and measurements across the site/structure, as well as a number of physical testing and assessment techniques. All relevant findings are conveyed in a concise and easy to read report. See our services page for a further breakdown of our capabilities.</>,
  },
  image:{
    alt: 'Structural Assessments',
    url: 'about.jpg',
    width: 430,
    class: 'mw-90 border-jordyblue mx-auto d-block'
  },
};

const About = () =>  (
    <section className='container-fluid min-vh-100 p-0' id={about.id}>
      <div className='container-fluid p-0 min-vh-100 d-flex flex-column'>
        <div className='container-fluid flex-fill d-flex justify-content-center align-items-center bg-crusta pt-gen pb-5'>
          <div className='container justify-content-center align-items-center'>
            <Fade direction='left'>
            <div className='col animate-bottom'>
              <h2>{about.copy.intro}</h2>
            </div>
            </Fade>
          </div>
        </div>
        <div className='container-fluid flex-fill'>
          <div className='container'>
            <div className='row flex-wrap-reverse'>
              <div className='col-lg-6 py-5 animate-bottom'>
                <h3>{about.copy.title}</h3>
                {about.copy.copy}
              </div>
              <div className='col-lg-6 pt-5 animate-bottom'>
                <img src={about.image.url} alt={about.image.alt} width={about.image.width} className={about.image.class}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
)

export default About;
