import {Fade} from 'react-awesome-reveal';

const services = {
  id:'services',
  intro: 'Services',
  item01:{
    copy:{
      title: 'Forensic Engineering Reports',
      copy: <>Is your house cracked? Are doors and windows jamming?<br/><br/>There can be multiple causes of structural movement from reactive soil ground movement, to poor building workmanship. Finding the cause of structural distress and recommending the best repair options is our job.<br/><br/>At Structural Assessments we have over 30 years’ experience assessing damaged and faulty structures.<br/><br/>We can carry out structural assessments of building frames, geotechnical testing, Ground Penetrating Radar scanning, mould testing and more to find the cause or causes of your structural damage.<br/><br/>We work closely with building repair contractors so our recommendations can be acted on in a precise and timely manner.</>
    },
    image:{
      alt: 'Structural Assessments',
      url: 'img01.jpg',
      width: 430,
      class: 'mw-90 mx-auto d-block border-crusta'
    }
  },
  item02:{
    copy:{
      title: 'VCAT Expert witness reports',
      copy: <>We have acted as expert witnesses in hundreds of court cases, in VCAT, Supreme Court and AFCA.<br/><br/>Our success rate is second to none, for one simple reason, we will not act on behalf of clients that do not have a strong case. If we cannot find strong tangible evidence, we simply charge a call out fee.<br/><br/>We have acted as expert witnesses in cases of:</>
    },
    list: [
      'Defective waffle slabs',
      'Council trees causing structural damage',
      'Insurance claim rejections',
      'Defective structural framing',
      'Trench collapses',
      'Defective Earthworks',
      'Defective materials used in construction',
      'Site contamination'
    ],
    image:{
      alt: 'Structural Assessments',
      url: 'img02.png',
      width: 430,
      class: 'mw-90 mx-auto d-block border-jordyblue'
    }
  },
  item03:{
    copy:{
      title: 'Concrete Assessment - GPR scanning',
      copy: <>Using the latest in scanning technology we can scan concrete structures for defects such as:</>,
      copy1: <>We can follow up scans with concrete coring or concrete removal to verify defects. Visual assessment of deteriorated concrete structures can also be undertaken.</>
    },
    list: [
      'Concrete cancer',
      'Missing or defective reinforcement',
      'Defective concrete mixes',
      'Concrete beams that are not wide or deep enough'
    ],
    image:{
      alt: 'Structural Assessments',
      url: 'img03.jpg',
      width: 430,
      class: 'mw-90 mx-auto d-block border-crusta'
    },
    image1:{
      alt: 'Structural Assessments',
      url: 'img04.jpg',
      width: 430,
      class: 'mw-90 mx-auto d-block border-crusta'
    }
  },
  item04:{
    copy:{
      title: 'Frame Inspections',
      copy: <>Having a frame inspection conducted is a crucial step in assuring your dwelling is structurally sound and has been built in accordance with the intended design.<br/><br/>Our team offers frame inspection services to ensure your framing adheres to the relevant engineering drawings, AS 1684 Residential Timber Framed Construction, and the NCC Building Code of Australia.<br/><br/>Our framing inspections cover:</>
    },
    list: [
      'room size and ceiling height',
      'member size and spacing',
      'bracing',
      'connection detailing and overall quality of install'
    ],
    image:{
      alt: 'Structural Assessments',
      url: 'img05.jpg',
      width: 430,
      class: 'mw-90 mx-auto d-block border-jordyblue'
    }
  },
  item05:{
    copy:{
      title: 'High Access/ Drone Assessments',
      copy: <>Our teams are equipped with the latest qualifications and technology to ensure those out of reach areas can be safely and effectively examined and assessed.<br/><br/>Our team utilise high rope access and drones to conduct condition assessments on difficult to access structures including:</>,
      copy1: <>Dependent on access constraints, our team are qualified and utilise:</>
    },
    list: [
      'Bridges',
      'Large footprint building and roofs',
      'Road signs and structures',
      'Underside of balconies'
    ],
    image:{
      alt: 'Structural Assessments',
      url: 'img06.jpg',
      width: 430,
      class: 'mw-90 mx-auto d-block border-crusta'
    },
    list1: [
      'Rope access techniques (abseiling)',
      'Elevated Work Platform (EWP)',
      'Drone videography'
    ]
  },
  item06:{
    copy:{
      title: 'Weld Inspections',
      copy: <>Our experienced team offer weld inspection services throughout your project to ensure quality control is maintained before, during and following the welding process.<br/><br/>Our team assess and report on the onsite welds against the specified quality and strength, as well as relevant industry requirements to ensure future safety and longevity is maintained.</>
    },
    image:{
      alt: 'Structural Assessments',
      url: 'img07.jpg',
      width: 430,
      class: 'mw-90 mx-auto d-block border-jordyblue'
    }
  },
  item07:{
    copy:{
      title: 'Council and local infrastructure',
      copy: <>Our team can offer services for local and state councils, assisting with periodic assessment of assets for conditions, damage, and maintenance. Such services can apply for:</>
    },
    list: [
      'Buildings and offices',
      'Parks and recreation areas',
      'Civil assets and infrastructure',
      'Heritage infrastructure'
    ],
    image:{
      alt: 'Structural Assessments',
      url: 'img08.jpg',
      width: 430,
      class: 'mw-90 mx-auto d-block border-crusta'
    }
  },
  item08:{
    copy:{
      title: 'Tree impact and Damage assessments',
      copy: <>The influence of trees on a structure can be very damaging. Trees influence and change soil moisture conditions which can undermine footings. Very large tree roots can push and lift footings. Our team can assess your building and determine if nearby trees and vegetation have a likely effect on footing performance.</>
    },
    image:{
      alt: 'Structural Assessments',
      url: 'img09.jpg',
      width: 430,
      class: 'mw-90 mx-auto d-block border-jordyblue'
    }
  },
  item09:{
    copy:{
      title: 'Balcony balustrades and conditions assessment',
      copy: <>Balconies and terraces are subject to harsh conditions with weather and wind. This combined with widespread construction industry waterproofing issues can lead to deterioration and damage to balcony balustrade systems. Defective balconies and balustrades can lead to serious life-threatening injuries, particularly for children.<br/><br/>Older timber balconies or decks can also degrade with weathering.<br/><br/>Our team can assess your balcony as well as the balustrade system in accordance with AS1170, and other balcony standards to ensure it is compliant and in good order.</>
    },
    image:{
      alt: 'Structural Assessments',
      url: 'img10.jpg',
      width: 430,
      class: 'mw-90 mx-auto d-block border-crusta'
    }
  }
};

const listItems2 = services.item02.list.map((list) => <li key={list}>{list}</li>);
const listItems3 = services.item03.list.map((list) => <li key={list}>{list}</li>);
const listItems4 = services.item04.list.map((list) => <li key={list}>{list}</li>);
const listItems5 = services.item05.list.map((list) => <li key={list}>{list}</li>);
const listItems6 = services.item05.list1.map((list) => <li key={list}>{list}</li>);
const listItems7 = services.item07.list.map((list) => <li key={list}>{list}</li>);

const Services = () =>  (
    <section className='container-fluid min-vh-100 p-0' id={services.id}>
      <div className='container-fluid p-0 min-vh-100 d-flex flex-column'>
        <div className='container-fluid flex-fill d-flex justify-content-center align-items-center bg-jordyblue pt-gen pb-5'>
          <div className='container justify-content-center align-items-center'>
            <Fade direction='right'>
            <div className='col'>
              <h2>{services.intro}</h2>
            </div>
            </Fade>
          </div>
        </div>
        <Fade>
        <div className='container-fluid flex-fill'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6 pt-5'>
                <img src={services.item01.image.url} alt={services.item01.image.alt} width={services.item01.image.width} className={services.item01.image.class}/>
              </div>
              <div className='col-lg-6 py-5'>
                <h3>{services.item01.copy.title}</h3>
                {services.item01.copy.copy}
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid flex-fill'>
          <div className='container'>
            <div className='row flex-wrap-reverse'>
              <div className='col-lg-6 py-5'>
                <h3>{services.item02.copy.title}</h3>
                {services.item02.copy.copy}
                <br/><br/>
                <ul>{listItems2}</ul><br/>
              </div>
              <div className='col-lg-6 pt-5'>
                <img src={services.item02.image.url} alt={services.item02.image.alt} width={services.item02.image.width} className={services.item02.image.class}/>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid flex-fill'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6 pt-5'>
              <img src={services.item03.image.url} alt={services.item03.image.alt} width={services.item03.image.width} className={services.item03.image.class}/>
              <br/><br/>
              <img src={services.item03.image1.url} alt={services.item03.image1.alt} width={services.item03.image1.width} className={services.item03.image1.class}/>
              </div>
              <div className='col-lg-6 py-5'>
                <h3>{services.item03.copy.title}</h3>
                {services.item03.copy.copy}
                <br/><br/>
                <ul>{listItems3}</ul><br/>
                {services.item03.copy.copy1}
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid flex-fill'>
          <div className='container'>
            <div className='row flex-wrap-reverse'>
              <div className='col-lg-6 py-5'>
                <h3>{services.item04.copy.title}</h3>
                {services.item04.copy.copy}
                <br/><br/>
                <ul>{listItems4}</ul><br/>
              </div>
              <div className='col-lg-6 pt-5'>
                <img src={services.item04.image.url} alt={services.item04.image.alt} width={services.item04.image.width} className={services.item04.image.class}/>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid flex-fill'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6 pt-5'>
              <img src={services.item05.image.url} alt={services.item05.image.alt} width={services.item05.image.width} className={services.item05.image.class}/>
              </div>
              <div className='col-lg-6 py-5'>
                <h3>{services.item05.copy.title}</h3>
                {services.item05.copy.copy}
                <br/><br/>
                <ul>{listItems5}</ul><br/>
                {services.item05.copy.copy1}
                <br/><br/>
                <ul>{listItems6}</ul>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid flex-fill'>
          <div className='container'>
            <div className='row flex-wrap-reverse'>
              <div className='col-lg-6 py-5'>
                <h3>{services.item06.copy.title}</h3>
                {services.item06.copy.copy}<br/>
              </div>
              <div className='col-lg-6 pt-5'>
                <img src={services.item06.image.url} alt={services.item06.image.alt} width={services.item06.image.width} className={services.item06.image.class}/>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid flex-fill'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6 pt-5'>
              <img src={services.item07.image.url} alt={services.item07.image.alt} width={services.item07.image.width} className={services.item07.image.class}/>
              </div>
              <div className='col-lg-6 py-5'>
                <h3>{services.item07.copy.title}</h3>
                {services.item07.copy.copy}
                <br/><br/>
                <ul>{listItems7}</ul><br/>
                {services.item07.copy.copy1}
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid flex-fill'>
          <div className='container'>
            <div className='row flex-wrap-reverse'>
              <div className='col-lg-6 py-5'>
                <h3>{services.item08.copy.title}</h3>
                {services.item08.copy.copy}<br/>
              </div>
              <div className='col-lg-6 pt-5'>
                <img src={services.item08.image.url} alt={services.item08.image.alt} width={services.item08.image.width} className={services.item08.image.class}/>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid flex-fill'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6 pt-5'>
              <img src={services.item09.image.url} alt={services.item09.image.alt} width={services.item09.image.width} className={services.item09.image.class}/>
              </div>
              <div className='col-lg-6 py-5'>
                <h3>{services.item09.copy.title}</h3>
                {services.item09.copy.copy}
              </div>
            </div>
          </div>
        </div>
        </Fade>
      </div>
    </section>
)

export default Services;
