import './App.css';
import Menu from './menu.js';
import Home from './home.js';
import About from './about.js';
import Services from './services.js';
import Contact from './contact.js';
import Footer from './footer.js';


const App = () =>(
    <section className='container-fluid px-0'>
      <Menu />
      <Home />
      <About />
      <Services />
      <Contact />
      <Footer />
    </section>
  )

export default App;
